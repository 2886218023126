<template>
  <div>
    <div class="ps-page--single">
      <div class="ps-breadcrumb">
        <div class="container">
          <ul class="breadcrumb">
            <li><a href="/">Home</a></li>
            <li><a href="#">Pages</a></li>
            <li>Dashboard</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ps-vendor-dashboard">
      <div class="container">
        <div class="ps-section__header">
          <h3>{{ shop.name }} Dashboard</h3>
        </div>
        <div class="ps-section__content">

        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from "axios";
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      shop: state => state.auth.shop
    }),
  },
  methods: {
    getOrders() {
      axios.get('orders').then((response) => {
        this.orders = response.data.data
      }).catch((error) => {
          console.log(error);
      });
    },
  },
}
</script>

<style lang="css" scoped>
#sidebarMenu {
  height: 1000px;
}
</style>